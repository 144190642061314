<template>
    <b-row>
        <b-col cols="12">
            <b-form-group :label="$t(labelPrefix+'find_by')">
                <multiselect
                    v-model="inputVal.find_by"
                    name="find_by"
                    :options="['firstname_lastname','company','api_customer_id', 'kva_number','api_order_id', 'invoice_number']"
                    :multiple="true"
                />
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>

import {mapGetters} from "vuex";

export default {
    name: 'MapBankTransactionsOptions',
    props: {
        'value': [String, Number, Object],
    },
    data() {
        return {
            loading: true,
            labelPrefix: 'settings.workflow.label.',
        }
    },
    methods: {},
    computed: {
        inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        },
    },
}
</script>